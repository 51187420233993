import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { BASE_URL } from "../../../global";
import { ToastContainer, toast } from "react-toastify";
import { editstdMastersEQPGuide } from "./guide";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import HelpIcon from "@mui/icons-material/Help";
import {
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  Toolbar,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import { ClassicTable } from "../../../utils/components/Styles";
import TablePagination from "@mui/material/TablePagination";
import Box from "@mui/material/Box";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import MultiValueInputModal from "../../common/multiValueInputModal";
import axiosWithToken from "../../../utils/components/axiosTokenConfig";
import { allSettings } from "../../../utils/components/allSettings";
import {
  materialtypeOp,
  modalConfigs,
  mode,
  parameters,
  paratype,
  readingColumns,
} from "./constant";
import { handleSubmit } from "./submitHandler";
import {
  getDiscipline,
  getRanges,
  getStandard,
  getUnitMasterArray,
} from "./fetchHandler";
const VisuallyHiddenInput = styled("input")`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;

export default function EditStandard(props) {
  const params = useParams();
  const editstdMastersEQPGuides = editstdMastersEQPGuide();
  const [page, setPage] = React.useState(0);
  const [totalRows, setTotalRows] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [standardDetails, setStandardDetails] = React.useState({
    standardName: "",
    ids: "",
    serialNo: "",
    make: "",
    model: "",
    location: "",
    calDate: "",
    validUpto: "",
    traceability: "",
    certificateNumber: "",
    EQMType: 1,
    materialtype: "",
    expansionCoefficient: "",
    certificateUrl: "",
  });
  const [disciplines, setDisciplineArray] = React.useState([]);
  const [srfObject, createSrfObject] = React.useState({});
  const [standardRanges, setStandardRanges] = React.useState([]);
  const [curruntsetDecipline, setDeciplines] = React.useState("");
  const [readingRows, setReadingRows] = React.useState([
    ["", "", "", "", "", "", "", "", "", "","","", "", "", "", "", "", "", "", ""],
  ]);
  const [readingRangeValue, setReadingRangeValue] = React.useState([
    ["", "", "","","", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""],
  ]);
  const [unitArray, setUnitArray] = React.useState([]);
  const [units, setUnits] = React.useState([]);
  const [percent, setPercent] = React.useState(false);
  const [plus, setPlus] = React.useState(false);
  const [acc, setAccuracy] = React.useState(false);
  const [mnv, setMnv] = React.useState(true);

  const [filteredSettings, setFilteredSettings] = React.useState([]);

  const initiateRanges = () => {
    let rows = [];
    let rowsSpace = [];
    for (let i = 0; i < standardRanges.length; i++) {
      rowsSpace.push([
        "",
        ((standardRanges[i].rangeName || "|").split("|")[0] || "#").split(
          "#"
        )[1],
        ((standardRanges[i].rangeName || "|").split("|")[1] || "#").split(
          "#"
        )[1],
        (standardRanges[i].mode || "#").split("#")[1],
        (standardRanges[i].parameter || "#").split("#")[1],
        (standardRanges[i].paratype || "#").split("#")[1],
        (standardRanges[i].uncertainty || "#").split("#")[1],
        (standardRanges[i].accuracy || "#").split("#")[1],
        (standardRanges[i].percentOfOutput || "#").split("#")[1],
        (standardRanges[i].plusValue || "#").split("#")[1],
        (standardRanges[i].stability || "#").split("#")[1],
        (standardRanges[i].uniformity || "#").split("#")[1],
        (standardRanges[i].axialUniformity || "#").split("#")[1],
        (standardRanges[i].radialUniformity || "#").split("#")[1],
        (standardRanges[i].reactionTime || "#").split("#")[1],
        (standardRanges[i].gravity || "#").split("#")[1],
        (standardRanges[i].drift || "#").split("#")[1],
        (standardRanges[i].cmv || "#").split("#")[1],
        (standardRanges[i].mpe || "#").split("#")[1],
        (standardRanges[i].materialDensity || "#").split("#")[1],
      ]);
      rows.push([
        standardRanges[i].id,
        ((standardRanges[i].rangeName || "|").split("|")[0] || "#").split(
          "#"
        )[0],
        ((standardRanges[i].rangeName || "|").split("|")[1] || "#").split(
          "#"
        )[0],
        standardRanges[i].mode,
        standardRanges[i].parameter,
        standardRanges[i].paratype,
        standardRanges[i].uncertainty,
        standardRanges[i].accuracy,
        standardRanges[i].percentOfOutput,
        standardRanges[i].plusValue,
        standardRanges[i].stability,
        standardRanges[i].uniformity,
        standardRanges[i].axialUniformity,
        standardRanges[i].radialUniformity,
        standardRanges[i].reactionTime,
        standardRanges[i].gravity,
        standardRanges[i].drift,
        standardRanges[i].cmv,
        standardRanges[i].mpe,
        standardRanges[i].materialDensity,
      ]);
    }
    setReadingRows(rows);
    setReadingRangeValue(rowsSpace);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const data = new FormData();
    data.append("fileToUpload", file);

    axiosWithToken
      .post(BASE_URL + `fileUpload/fileUpload.php`, data)
      .then((res) => {
        if (res.status === 200) {
          setStandardDetails({
            ...standardDetails,
            certificateUrl: res.data,
          });
          toast.success("File Uploaded Successfully!");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const updateSrfObject = (key, value) => {
    let newSrfObject = {
      ...srfObject,
    };
    newSrfObject[key] = value;
    createSrfObject(newSrfObject);
  };
  const updateCellValue = (rowIndex, colIndex, value) => {
    const newRows = [...readingRows];
    newRows[rowIndex][colIndex] = value;
    setReadingRows([...newRows]);
  };

  const updateRangeCellValue = (rowIndex, colIndex, value) => {
    const newRowss = [...readingRangeValue];
    newRowss[rowIndex][colIndex] = value;
    setReadingRangeValue([...newRowss]);
  };

  const addRangeRow = (tableIndex) => {
    let newReadingRows = [...readingRows];
    if (tableIndex !== undefined) {
      newReadingRows.push([...readingRows[tableIndex]]);
      newReadingRows[newReadingRows.length - 1][0] = 0;
    } else
      newReadingRows.push(
        ["", "", "", "", "", "", "", "", "", "","","", "", "", "", "", "", "", "", ""]
      );
    setReadingRows([...newReadingRows]);

    let newReadingRowss = [...readingRangeValue];
    if (tableIndex !== undefined)
      newReadingRowss.push([...readingRangeValue[tableIndex]]);
    else
      newReadingRowss.push([
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
      ]);
    setReadingRangeValue([...newReadingRowss]);
    setTotalRows(totalRows + 1);
  };

  const deleteReadingRow = (rowIndex, id) => {
    let newReadingRows = [...readingRows];
    if (newReadingRows[rowIndex] != undefined && id) {
      let url = BASE_URL;
      axiosWithToken
        .delete(url + `standardRanges/${id}`)
        .then((res) => {
          console.log(res.data);
        })
        .catch((error) => {
          toast.error("Something Went Wrong!");
        });
    }
    newReadingRows.splice(rowIndex, 1);
    setReadingRows([...newReadingRows]);
    setTotalRows(totalRows - 1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const TablePaginationActions = (props) => {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  };

  useEffect(async () => {
    const disciplineData = await getDiscipline();
    const unitData = await getUnitMasterArray();
    setDisciplineArray(disciplineData);
    setUnitArray(unitData);
  }, []);

  useEffect(async () => {
    const { totalCount, standardRanges } = await getRanges(
      params.id,
      page,
      rowsPerPage
    );
    setTotalRows(totalCount);
    setStandardRanges(standardRanges);
  }, [params.id != 0, page, rowsPerPage]);

  useEffect(() => {
    if (params.id != 0) {
      initiateRanges();
    }
  }, [params.id != 0, standardRanges]);

  useEffect(() => {
    if (params.id != 0) {
      getStandard(
        params.id,
        unitArray,
        standardDetails,
        setStandardDetails,
        createSrfObject,
        setDeciplines,
        setPercent,
        setPlus,
        setMnv,
        setUnits
      );
    }
  }, [unitArray]);

  useEffect(() => {
    const fetchClientSetting = async () => {
      try {
        const AllSettings = await allSettings();

        const allSettingsKeyNames = materialtypeOp.map((ele) =>
          ele.toLowerCase()
        );

        // Filter AllSettings based on the available keyNames in materialtypeOp
        const filteredSettings = AllSettings.filter((setting) =>
          allSettingsKeyNames.includes(setting.keyName.toLowerCase())
        );
        setFilteredSettings(filteredSettings);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchClientSetting();
  }, []);

  useEffect(() => {
    const expansionCoefficientfilter =
      filteredSettings?.find(
        (ele) => ele.keyName === standardDetails?.materialtype?.toLowerCase()
      )?.value || null;
    setStandardDetails((prevState) => ({
      ...prevState,
      expansionCoefficient: expansionCoefficientfilter,
    }));
  }, [standardDetails?.materialtype]);

  useEffect(() => {
    getRanges();
  }, [rowsPerPage, page]);
  return (
    <Paper sx={{ mt: 2, p: 2 }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h6" component="h6" style={{ float: "left" }}>
          {params.id != 0 ? "Edit" : "Create"} Master EQP
        </Typography>
        <Tooltip title="User Guide" placement="top-start">
          <Button
            onClick={() => {
              editstdMastersEQPGuides.drive();
            }}
          >
            <HelpIcon />
          </Button>
        </Tooltip>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={6} md={4} lg={2}>
          <Autocomplete
            size="small"
            id="edit_stdMastersEQP_discipline"
            options={disciplines.map(({ id, name }) => ({
              label: id + ", " + name,
            }))}
            value={curruntsetDecipline || ""}
            renderInput={(params) => (
              <TextField {...params} label="Discipline *" />
            )}
            onInputChange={(event, newInputValue) => {
              setDeciplines(newInputValue);
              let toggle = [true, true, true, true];
              disciplines.forEach((el) => {
                if (el.id == newInputValue.split(",")[0]) {
                  if (el.disciplineKey == "0") {
                    toggle[0] = false;
                    toggle[1] = false;
                  } else if (el.disciplineKey == "2") {
                    toggle[3] = false;
                  }
                }
              });

              const unitArr = [""];
              for (let i = 0; i < unitArray.length; i++) {
                if (
                  parseInt(newInputValue.split(",")[0]) ==
                  unitArray[i].disciplineId
                ) {
                  unitArr.push(unitArray[i]);
                }
              }
              setUnits(unitArr);

              updateSrfObject(
                "disciplineId",
                parseInt(newInputValue.split(",")[0])
              );

              setPercent(toggle[0]);
              setPlus(toggle[1]);
              setMnv(toggle[3]);
            }}
            disabled={params.id != 0}
          />
        </Grid>

        <Grid item xs={6} sm={6} md={4} lg={2}>
          <TextField
            id="edit_stdMastersEQP_master_eqp"
            label="Master EQP Name *"
            size="small"
            value={standardDetails?.standardName}
            fullWidth
            variant="outlined"
            onChange={(e) =>
              setStandardDetails({
                ...standardDetails,
                standardName: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={6} sm={6} md={4} lg={2}>
          <TextField
            id="edit_stdMastersEQP_master_id"
            label="id"
            size="small"
            value={standardDetails?.ids}
            fullWidth
            variant="outlined"
            onChange={(e) =>
              setStandardDetails({
                ...standardDetails,
                ids: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={6} sm={6} md={4} lg={2}>
          <TextField
            id="edit_stdMastersEQP_master_srno"
            label="Sr No. "
            size="small"
            value={standardDetails?.serialNo}
            fullWidth
            variant="outlined"
            onChange={(e) =>
              setStandardDetails({
                ...standardDetails,
                serialNo: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={6} sm={6} md={4} lg={2}>
          <TextField
            id="edit_stdMastersEQP_master_make"
            label="Make"
            size="small"
            value={standardDetails?.make}
            fullWidth
            variant="outlined"
            onChange={(e) =>
              setStandardDetails({
                ...standardDetails,
                make: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={6} sm={6} md={4} lg={2}>
          <TextField
            id="edit_stdMastersEQP_master_model"
            label="Model "
            size="small"
            value={standardDetails?.model}
            fullWidth
            variant="outlined"
            onChange={(e) =>
              setStandardDetails({
                ...standardDetails,
                model: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={6} sm={6} md={4} lg={2}>
          <TextField
            id="edit_stdMastersEQP_master_traceability"
            label="Tracability "
            size="small"
            fullWidth
            value={standardDetails?.certificateNumber}
            variant="outlined"
            onChange={(e) =>
              setStandardDetails({
                ...standardDetails,
                certificateNumber: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={6} sm={6} md={4} lg={2}>
          <TextField
            id="edit_stdMastersEQP_master_calagency"
            label="Cal. Agency."
            size="small"
            fullWidth
            value={standardDetails?.traceability}
            variant="outlined"
            onChange={(e) =>
              setStandardDetails({
                ...standardDetails,
                traceability: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={6} sm={6} md={4} lg={2}>
          <TextField
            id="edit_stdMastersEQP_master_location"
            label="Location "
            size="small"
            fullWidth
            variant="outlined"
            value={standardDetails?.location}
            onChange={(e) =>
              setStandardDetails({
                ...standardDetails,
                location: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={6} sm={6} md={4} lg={6}>
          <div
            style={{
              display: "flex",
              columnGap: "50px",
              rowGap: "20px",
              alignItems: "center",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            {modalConfigs.map((config, index) => (
              <MultiValueInputModal
                key={`${index},${srfObject.disciplineId}`}
                buttonLabel={config.buttonLabel}
                transformedStringLabel={config.transformedStringLabel}
                inputStates={srfObject}
                updateInputObject={updateSrfObject}
                disciplineId={+curruntsetDecipline?.split(", ")?.[0]}
              />
            ))}
          </div>
        </Grid>
        <Grid item xs={6} sm={6} md={4} lg={2}>
          <Button
            id="create_document_uploadfile"
            component="label"
            variant="contained"
            startIcon={<CloudUploadIcon />}
            sx={{ width: "100%", m: "auto" }}
            disabled
          >
            {standardDetails?.certificateUrl == null
              ? "Upload file"
              : standardDetails?.certificateUrl.split("_").pop()}
            <VisuallyHiddenInput type="file" onChange={handleFileUpload} /> File
            Upload
          </Button>
        </Grid>
        <Grid item xs={6} sm={6} md={4} lg={2}>
          <Autocomplete
            size="small"
            id="edit_stdMastersEQP_master_materialtype"
            options={materialtypeOp.map((e) => ({
              label: e,
            }))}
            renderInput={(params) => (
              <TextField {...params} label="Material Type " />
            )}
            value={
              standardDetails?.materialtype === ""
                ? "select material type"
                : standardDetails?.materialtype
            }
            onInputChange={(event, newInputValue) => {
              setStandardDetails({
                ...standardDetails,
                materialtype: newInputValue,
              });
            }}
          />
        </Grid>
        <Grid
          item
          xs={6}
          sm={6}
          md={4}
          lg={2}
          id="edit_stdMastersEQP_master_caldate"
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              slotProps={{ textField: { size: "small", fullWidth: true } }}
              label="Cal Date"
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              value={
                standardDetails?.calDate
                  ? new Date(standardDetails?.calDate)
                  : ""
              }
              onChange={(newValue) => {
                setStandardDetails({
                  ...standardDetails,
                  calDate: newValue,
                });
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid
          item
          xs={6}
          sm={6}
          md={4}
          lg={2}
          id="edit_stdMastersEQP_master_duedate"
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              slotProps={{ textField: { size: "small", fullWidth: true } }}
              label="Due Date"
              inputFormat="dd/MM/yyyy"
              format="dd/MM/yyyy"
              value={
                standardDetails?.validUpto
                  ? new Date(standardDetails?.validUpto)
                  : ""
              }
              onChange={(newValue) => {
                setStandardDetails({
                  ...standardDetails,
                  validUpto: newValue,
                });
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={6} sm={12} md={4} lg={3}>
          <FormControlLabel
            control={
              <Checkbox
                checked={standardDetails?.EQMType == 2}
                onChange={(e) => {
                  setStandardDetails({
                    ...standardDetails,
                    EQMType: e.target.checked ? 2 : 1,
                  });
                }}
                color="primary"
              />
            }
            label={
              <Typography variant="body1">Is Supportive Instrument</Typography>
            }
          />
        </Grid>
      </Grid>
      <br />
      <hr />
      <br />
      <h4 style={{ "margin-bottom": "0px" }}>Master Ranges</h4>
      <Button
        id="edit_stdMastersEQP_master_addmasterrange"
        style={{ float: "right", marginBottom: "15px" }}
        onClick={(e) => {
          addRangeRow();
        }}
        variant="contained"
        size="small"
      >
        add master uncertinity
      </Button>
      <div style={{ width: "100%", overflow: "auto" }}>
        <ClassicTable>
          <Table sx={{ minWidth: 660 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {readingColumns[0]
                  .filter(
                    (e) =>
                      e.toggle === undefined ||
                      (e.toggle === "plus" && !plus) ||
                      (e.toggle === "mnv" && !mnv)
                  )
                  .map((column, index) => (
                    <TableCell key={column.field}>
                      <Typography noWrap>{column.headerName}</Typography>
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {readingRows?.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>
                    {index + 1}
                    <Button
                      id="edit_stdMastersEQP_master_copymasterrange"
                      onClick={(e) => {
                        addRangeRow(index);
                      }}
                    >
                      <ContentCopyIcon />
                    </Button>
                  </TableCell>
                  {row.map((cell, cellIndex) => {
                    if (
                      cellIndex != 3 &&
                      cellIndex != 4 &&
                      cellIndex != 5 &&
                      cellIndex != 7 &&
                      cellIndex != 0
                    ) {
                      if ((cellIndex === 8 || cellIndex === 9) && plus)
                        return "";
                      if (
                        (cellIndex === 17 ||
                          cellIndex === 18 ||
                          cellIndex === 19) &&
                        mnv
                      )
                        return "";
                      return (
                        <TableCell>
                          <TextField
                            id="edit_stdMastersEQP_master_callowerpt"
                            size="small"
                            value={cell?.split("#")[0]}
                            onChange={(e) => {
                              updateCellValue(index, cellIndex, e.target.value);
                            }}
                          ></TextField>
                          <select
                            id="edit_stdMastersEQP_master_editunit"
                            styles={{ width: "100%" }}
                            onChange={(e) => {
                              updateRangeCellValue(
                                index,
                                cellIndex,
                                e.target.value
                              );
                            }}
                          >
                            <option value={""} key={""} selected>
                              {""}
                            </option>
                            {units?.map((unit) => {
                              if (
                                unit.symbol ==
                                readingRangeValue[index][cellIndex]
                              ) {
                                return (
                                  <option
                                    value={unit.symbol}
                                    key={unit.symbol}
                                    selected
                                  >
                                    {unit.symbol}
                                  </option>
                                );
                              } else {
                                return (
                                  <option value={unit.symbol} key={unit.symbol}>
                                    {unit.symbol}
                                  </option>
                                );
                              }
                            })}
                          </select>
                        </TableCell>
                      );
                    }
                    if (cellIndex == 3 && !plus) {
                      return (
                        <TableCell>
                          <Autocomplete
                            style={{ width: "150px" }}
                            size="small"
                            id="combo-box-demo"
                            options={mode.map((e) => ({
                              label: e,
                            }))}
                            value={cell}
                            renderInput={(params) => (
                              <TextField {...params} label="Mode *" />
                            )}
                            onInputChange={(event, newInputValue) => {
                              updateCellValue(index, cellIndex, newInputValue);
                            }}
                            disabled={plus}
                          />
                          <select
                            disabled={true}
                            styles={{ width: "100%" }}
                            onChange={(e) => {
                              updateRangeCellValue(
                                index,
                                cellIndex,
                                e.target.value
                              );
                            }}
                          >
                            <option value={""} key={""} selected>
                              {""}
                            </option>
                            {units?.map((unit) => {
                              if (
                                unit.symbol ==
                                readingRangeValue[index][cellIndex]
                              ) {
                                return (
                                  <option
                                    value={unit.symbol}
                                    key={unit.symbol}
                                    selected
                                  >
                                    {unit.symbol}
                                  </option>
                                );
                              } else {
                                return (
                                  <option value={unit.symbol} key={unit.symbol}>
                                    {unit.symbol}
                                  </option>
                                );
                              }
                            })}
                          </select>
                        </TableCell>
                      );
                    }
                    if (cellIndex == 4 && !plus) {
                      return (
                        <TableCell>
                          <Autocomplete
                            style={{ width: "250px" }}
                            size="small"
                            id="combo-box-demo"
                            options={
                              parameters[row[3]]
                                ? parameters[row[3]].map((e) => ({
                                    label: e,
                                  }))
                                : []
                            }
                            value={cell}
                            renderInput={(params) => (
                              <TextField {...params} label="Parameter *" />
                            )}
                            onInputChange={(event, newInputValue) => {
                              updateCellValue(index, cellIndex, newInputValue);
                            }}
                            disabled={plus || !row[3] ? true : false}
                          />
                          <select
                            disabled={true}
                            styles={{ width: "100%" }}
                            onChange={(e) => {
                              updateRangeCellValue(
                                index,
                                cellIndex,
                                e.target.value
                              );
                            }}
                          >
                            <option value={""} key={""} selected>
                              {""}
                            </option>
                            {units?.map((unit) => {
                              if (
                                unit.symbol ==
                                readingRangeValue[index][cellIndex]
                              ) {
                                return (
                                  <option
                                    value={unit.symbol}
                                    key={unit.symbol}
                                    selected
                                  >
                                    {unit.symbol}
                                  </option>
                                );
                              } else {
                                return (
                                  <option value={unit.symbol} key={unit.symbol}>
                                    {unit.symbol}
                                  </option>
                                );
                              }
                            })}
                          </select>
                        </TableCell>
                      );
                    }
                    if (cellIndex == 5 && !plus) {
                      return (
                        <TableCell>
                          {plus ||
                          !(paratype[row[3]] && paratype[row[3]][row[4]]) ? (
                            <TextField
                              size="small"
                              disabled={acc}
                              value={cell}
                              onChange={(e) => {
                                updateCellValue(
                                  index,
                                  cellIndex,
                                  e.target.value
                                );
                              }}
                            ></TextField>
                          ) : (
                            <Autocomplete
                              style={{ width: "150px" }}
                              size="small"
                              id="combo-box-demo"
                              value={cell}
                              options={
                                paratype[row[3]] && paratype[row[3]][row[4]]
                                  ? paratype[row[3]][row[4]].map((e) => ({
                                      label: e,
                                    }))
                                  : []
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Parameter Type *"
                                />
                              )}
                              onInputChange={(event, newInputValue) => {
                                updateCellValue(
                                  index,
                                  cellIndex,
                                  newInputValue
                                );
                              }}
                              // disabled={
                              //   plus ||
                              //   !(paratype[row[3]] && paratype[row[3]][row[4]])
                              //     ? true
                              //     : false
                              // }
                            />
                          )}
                          <select
                            disabled={true}
                            styles={{ width: "100%" }}
                            onChange={(e) => {
                              updateRangeCellValue(
                                index,
                                cellIndex,
                                e.target.value
                              );
                            }}
                          >
                            <option value={""} key={""} selected>
                              {""}
                            </option>
                            {units?.map((unit) => {
                              if (
                                unit.symbol ==
                                readingRangeValue[index][cellIndex]
                              ) {
                                return (
                                  <option
                                    value={unit.symbol}
                                    key={unit.symbol}
                                    selected
                                  >
                                    {unit.symbol}
                                  </option>
                                );
                              } else {
                                return (
                                  <option value={unit.symbol} key={unit.symbol}>
                                    {unit.symbol}
                                  </option>
                                );
                              }
                            })}
                          </select>
                        </TableCell>
                      );
                    }
                    if (cellIndex == 7) {
                      return (
                        <TableCell>
                          <TextField
                            size="small"
                            disabled={acc}
                            value={acc ? null : cell?.split("#")[0]}
                            onChange={(e) => {
                              updateCellValue(index, cellIndex, e.target.value);
                            }}
                          ></TextField>
                          <select
                            disabled={acc}
                            styles={{ width: "100%" }}
                            onChange={(e) => {
                              updateRangeCellValue(
                                index,
                                cellIndex,
                                e.target.value
                              );
                            }}
                          >
                            <option value={""} key={""} selected>
                              {""}
                            </option>
                            {units?.map((unit) => {
                              if (
                                unit.symbol ==
                                readingRangeValue[index][cellIndex]
                              ) {
                                return (
                                  <option
                                    value={unit.symbol}
                                    key={unit.symbol}
                                    selected
                                  >
                                    {unit.symbol}
                                  </option>
                                );
                              } else {
                                return (
                                  <option value={unit.symbol} key={unit.symbol}>
                                    {unit.symbol}
                                  </option>
                                );
                              }
                            })}
                          </select>
                        </TableCell>
                      );
                    }
                  })}
                  <TableCell>
                    <DeleteIcon
                      id="edit_stdMastersEQP_dispatchchalland_delete"
                      style={{ color: "#dc3545" }}
                      onClick={(e) => {
                        deleteReadingRow(index, row[0]);
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ClassicTable>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100, { label: "All", value: -1 }]}
          count={totalRows}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          component="div"
          colSpan={3}
          SelectProps={{
            inputProps: {
              "aria-label": "rows per page",
            },
            native: true,
          }}
          ActionsComponent={TablePaginationActions}
        />
      </div>

      <br />
      <Toolbar
        style={{
          padding: "0px",
          overflow: "auto",
          display: "flex",
          justifyContent: "flex-end",
          gap: "20px",
        }}
      >
        <Button
          variant="contained"
          style={{ backgroundColor: "grey" }}
          size="small"
          onClick={() => {
            if (window.confirm("Are you sure you want to cancel?")) {
              window.history.back();
            }
          }}
        >
          Cancel
        </Button>
        <Button
          id="edit_stdMastersEQP_dispatchchalland_update"
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          onClick={() => {
            handleSubmit(
              props,
              standardDetails,
              srfObject,
              readingRows,
              readingRangeValue,
              params,
              percent
            );
          }}
        >
          {params.id != 0 ? "update" : "save"}
        </Button>
      </Toolbar>
      <ToastContainer />
    </Paper>
  );
}
